<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="4">
          <v-text-field
            v-model="formData.nome"
            placeholder="Nome"
            outlined
            dense
            :clearable="true"
            label="Nome"
            required
            :rules="rules.genericRules"
            :disabled="viewOnly"
          >
          </v-text-field>

          <v-autocomplete
            v-model="formData.especialidades"
            :items="listEspecialidades"
            hide-no-data
            item-text="nome"
            item-value="id"
            placeholder="Especialidade"
            outlined
            dense
            clear
            multiple
            small-chips
            chips
            label="Especialidades"
            required
            :rules="rules.multiSelectRules"
            deletable-chips
            :disabled="viewOnly"
          ></v-autocomplete>

          <v-text-field
            v-model="formData.crm"
            placeholder="CRM"
            outlined
            dense
            :clearable="true"
            label="CRM"
            required
            :rules="rules.genericRules"
            v-mask="'######-AA'"
             :disabled="viewOnly"
          >
          </v-text-field>

          <v-text-field
            v-model="formData.email"
            placeholder="Email"
            outlined
            dense
            :clearable="true"
            label="Email"
            required
            :rules="rules.emailRules"
             :disabled="viewOnly"
          >
          </v-text-field>

          <v-text-field
            v-if="isEdit"
            v-model="formData.createdAt"
            placeholder="Data de Aceite dos termos"
            outlined
            dense
            :clearable="true"
            label="Data de Aceite dos termos"
            disabled
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4" v-if="!viewOnly">
      <v-btn
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar Médico" : "Criar Médico" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { rules } from "@/utils/rules.js";
export default {
  name: "MedicoSolicitanteFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    medico: {
      type: Object,
      default: () => {},
    },
    listEspecialidades: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formData: {
      nome: null,
      especialidades: [],
      crm: null,
      email: null,
    },
    rules: rules,
    valid: true,
    isEdit: false,
    viewOnly: false,
  }),
  created() {
    if (this.$route.name === "MedicoSolicitanteEditar") {
      this.formData.nome = this.medico.nome;
      this.formData.especialidades = this.medico.especialidades.map(
        (item) => item.id
      );
      this.formData.crm = this.medico.crm;
      this.formData.email = this.medico.email;
      this.formData.createdAt = moment(
        this.medico.createdAt,
        "YYYY-MM-DD HH:mm"
      ).format("DD/MM/YYYY HH:mm");
      this.isEdit = true;
    }
    if (this.$route.name === "MedicoSolicitanteVisualizar") {
      this.formData.nome = this.medico.nome;
      this.formData.especialidades = this.medico.especialidades.map(
        (item) => item.id
      );
      this.formData.crm = this.medico.crm;
      this.formData.email = this.medico.email;
      this.formData.createdAt = moment(
        this.medico.createdAt,
        "YYYY-MM-DD HH:mm"
      ).format("DD/MM/YYYY HH:mm");
      this.viewOnly = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.formData);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
