<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/medicos-solicitantes"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <MedicoSolicitanteFormulario
          @send="editar"
          :medico="medico"
          :loadingBtn="loadingBtn"
          :listEspecialidades="listEspecialidades"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Medico Solicitante editado com sucesso"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { listarEspecialidade } from "@/services/especialidades.js";
import {
  exibitMedicoSolicitante,
  editarMedicoSolicitante,
} from "@/services/medico-solicitante";
import MedicoSolicitanteFormulario from "@/components/medicos-solicitantes/MedicoSolicitanteFormulario.vue";
export default {
  name: "MedicoSolicitanteEditar",
  components: { MedicoSolicitanteFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Médicos Solicitantes",
        disabled: false,
        to: "/medicos-solicitantes",
      },
      {
        text: "Editar Médico Solicitante",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    medico: {},
    loading: false,
    listEspecialidades: [],
  }),
  beforeCreate() {
    if (this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({ path: "/" });
    }
  },
  created() {
    this.exibir();
    this.listEspecialidade();
  },
  methods: {
    async editar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarMedicoSolicitante(this.$route.params.id, event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibitMedicoSolicitante(this.$route.params.id);
        this.medico = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goTo() {
      this.sucess = false;
      this.$router.push({ name: "MedicosSolicitantes" });
    },
    async listEspecialidade() {
      const resp = await listarEspecialidade();
      this.listEspecialidades = resp.data;
    },
  },
};
</script>

<style></style>
